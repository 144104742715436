<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-xl-9 col-lg-10 col-md-12">
                <div class="card card-top-line no-shadow send-money-area">
                    <div class="header">
                        <h2>Please fill transfer details</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <!-- <money_exchange_calculation></money_exchange_calculation> -->
                                <ul class="list-unstyled mb-4 send-money-card">
                                    <li>
                                        <div class="phone-with-country d-flex justify-content-between align-items-center">
                                            <div class="select-country">
                                                <div class="form-group form-select single-multiselect mb-0">
                                                    <span class="float-label">
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="moneyDetails.currencyFrom"   
                                                            :show-labels="false" 
                                                            :options="currencyOptions"
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name"
                                                            :custom-label="currencyFlag"
                                                            @select="fromCurrencySelected">
                                                            <template v-slot:singleLabel="{ option }">
                                                                <span class="mr-2" :class="option.flagClass"></span>
                                                                <span class="option-title">
                                                                    <span>{{ option.name }}</span>
                                                                </span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <div class="option-title">
                                                                    <span class="mr-2" :class="option.flagClass"></span>
                                                                    <span>{{ option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="add-number">
                                                <div class="form-group mb-0">
                                                    <span class="float-label">
                                                        <input type="text" v-model="moneyDetails.amountFrom" class="form-control" id="amountFrom" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)" @input="setcurrencyOnTo()">
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="text-center exchange-icon">
                                        <a href="javascript:void(0);" @click="swipeTextbox()"><i class="fa fa-exchange"></i></a>
                                    </li>
                                    <li>
                                        <div class="phone-with-country d-flex justify-content-between align-items-center">
                                            <div class="select-country">
                                                <div class="form-group mb-0 form-select single-multiselect">
                                                    <span class="float-label">
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="moneyDetails.currencyTo"   
                                                            :show-labels="false" 
                                                            :options="destcurrency"
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name"
                                                            :custom-label="currencyFlag"
                                                            @select="toCurrencySelected">
                                                            <template v-slot:singleLabel="{ option }">
                                                                <span class="mr-2" :class="option.flagClass"></span>
                                                                <span class="option-title">
                                                                    <span>{{ option.name }}</span>
                                                                </span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <div class="option-title">
                                                                    <span class="mr-2" :class="option.flagClass"></span>
                                                                    <span>{{ option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="add-number">
                                                <div class="form-group mb-0">
                                                    <span class="float-label">
                                                        <input type="text" v-model="moneyDetails.amountTo" class="form-control" id="amountTo" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)"  @input="setcurrencyOnFrom()">
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mt-4">
                                        <div class="country-dropdown">
                                            <div class="form-group form-select multiselect-grp">
                                                <span class="float-label">
                                                <label for="Country">How they Receive it</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="moneyDetails.receiveMoneyBy" 
                                                        :show-labels="false" 
                                                        :options="receiveMoneyByOptions" 
                                                        :searchable="false" 
                                                        label="name" 
                                                        track-by="value">
                                                        <template v-slot:singleLabel="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                    </multiselect>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mt-4">
                                        <div class="country-dropdown">
                                            <div class="form-group form-select multiselect-grp">
                                                <span class="float-label">
                                                <label for="Country">Reason for Transfer</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="moneyDetails.reasonforTransfer" 
                                                        :show-labels="false" 
                                                        :options="reasonforTransferOption" 
                                                        :searchable="true"
                                                        label="name" 
                                                        track-by="name">
                                                        <template v-slot:singleLabel="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                    </multiselect>
                                                </span>
                                            </div>
                                        </div>
                                        
                                    </li> 
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <div class="card border no-shadow">
                                    <!-- <money_exchange_timeline></money_exchange_timeline> -->
                                    <ul class="list-unstyled mb-0 profile-timeline-list">
                                        <li class="timeline-list green" :class="{'active':true}">
                                            <p>
                                                <span>Exchange Rate</span>
                                                <strong>{{ timelineLists.exchangerate }}</strong>
                                            </p>
                                        </li>
                                        <li class="timeline-list green" :class="{'active':true}">
                                            <p>
                                                <span>Transfer Fee</span>
                                                <strong>{{ timelineLists.transferfee }}</strong>
                                            </p>
                                        </li>
                                        <li class="timeline-list green" :class="{'active':false}">
                                            <p>
                                                <span>Total Amount to Pay</span>
                                                <strong>{{ timelineLists.totalpay }}</strong>
                                            </p>
                                        </li>
                                        <!-- <li v-for="(timelineList,i) in timelineLists" :key="i" class="timeline-list green" :class="{'active':timelineList.isActive}">
                                            <p>
                                                <span>{{ timelineList.name }}</span>
                                                <strong>{{ timelineList.amount }}</strong>
                                            </p>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <b-button variant="primary btn-mw" size="lg" @click="sendMoneySubmit()">Submit</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-loader ref="loader"></main-loader>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
// import money_exchange_timeline from '@/components/admin/send_money/send_myself/MoneyExchangeTimeline.vue';
// import money_exchange_calculation from '@/components/admin/send_money/send_myself/MoneyExchangeCalculation.vue';
import VueMultiselect from 'vue-multiselect'
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default;
import {EventBus} from '@/eventBus';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
const $toast = useToast();
export default {
    name:'ReceiveMoneyBy',
    components: {
        BreadCrumb,
        // money_exchange_timeline,
        // money_exchange_calculation,
        'Multiselect': VueMultiselect,
        "main-loader": LoaderComponent,
    },data(){
        return{
            moneyDetails:{
                amountFrom:'',
                currencyFrom:null,//{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amountTo:'',
                currencyTo: null,//{ name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                receiveMoneyBy: { name:'',value: ''},
                reasonforTransfer:''
            },
            receiveMoneyByOptions:[
                {
                    name:'Mobile Money',
                    value:'mobile-money'
                },
                {
                    name:'Bank Deposit',
                    value:'bank-deposits'
                },
                {
                    name:'Cash Pickup',
                    value:'cash-pickup'
                },
                {
                    name:'Airtime',
                    value:'airtime'
                },
                {
                    name:'PayAngel Wallet',
                    value:'payangel-wallet'
                }
            ],

            reasonforTransferOption:[
                // 'Financial Support',
                // 'Funeral Support',
                // 'Pay / Salary',
                // 'Goods / Serviced Purchased',
                // 'School Fees / Education',
                // 'Mortgage Payment',
                // 'Property Purchase / Management',
                // 'Loan',
                // 'Charity / Donation / Dues',
                // 'Membership Dues'
            ],

            currencyOptions:[
                // { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                // { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                // { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                // { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],

            currencyRate:{
                GBP:{
                    GHS:9.97,
                    USD:1.31,
                    RUB:108.69,
                    GBP:0.00
                },

                GHS:{
                    GBP:0.10,
                    USD:0.13,
                    RUB:10.88,
                    GHS:0.00
                },

                USD:{
                    GHS:7.58,
                    GBP:0.76,
                    RUB:82.53,
                    USD:0.00
                },

                RUB:{
                    GHS:0.092,
                    GBP:0.0092,
                    USD:0.012,
                    RUB:0.00
                }
            },
            timelineLists:{
                exchangerate: '0.00',
                transferfee: '0.00',
                totalpay: '0.00'
            },
                
            countrys_options:[],
            destcurrency:[],
            walletDetails: null,
            conversionRate:''
        }
    },methods:{
        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },

        handleInput (e) {
            let stringValue = e.target.value.toString()
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,2})?$/
            if(!stringValue.match(regex) && this.moneyDetails.amountFrom !== '' && this.moneyDetails.amountTo !== '') {
                this.moneyDetails.amountFrom = null
                this.moneyDetails.amountTo = null
            }
            
        },

        checkDecimals (e) {
            let stringValue = e.target.value.toString();
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,1})?$/
            if (!stringValue.match(regex)
                && e.which != 8   // backspace
                && e.which != 46  // delete
                && (e.which < 48 || e.which > 57)
                && (e.which < 96 || e.which > 105)){
                    e.preventDefault();
                    return false;
                }
        },

        fromCurrencySelected(currency){
            // console.log('currency',currency);
            this.moneyDetails.currencyFrom = currency
            // call conversion rate
            if(this.moneyDetails.currencyTo && this.moneyDetails.currencyTo.walletid){
                this.getConversionrate();
            }
           
            if(this.moneyDetails.amountTo){
                this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo / this.conversionRate).toFixed(2);
                this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountTo / this.conversionRate).toFixed(2);
            //    this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[this.moneyDetails.currencyTo.name][this.moneyDetails.currencyFrom.name]).toFixed(2);            
            }

            if(currency.name ==  this.moneyDetails.currencyTo.name && this.moneyDetails.amountTo){
                this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo).toFixed(2);
                this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountTo).toFixed(2);
            }
        },

        toCurrencySelected(currency){
            this.moneyDetails.currencyTo = currency;
            // call conversion rate
            if(this.moneyDetails.currencyTo && this.moneyDetails.currencyFrom.walletid){
                this.getConversionrate();
            }
            
            if(this.moneyDetails.amountFrom){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.conversionRate).toFixed(2);   
                this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountFrom).toFixed(2); 
                // this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[this.moneyDetails.currencyFrom.name][this.moneyDetails.currencyTo.name]).toFixed(2);          
            }
            if(currency.name ==  this.moneyDetails.currencyFrom.name && this.moneyDetails.amountFrom){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom).toFixed(2);
                this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountFrom).toFixed(2);
            }
        },

        setcurrencyOnTo(){
            // var from = this.moneyDetails.currencyFrom.name;
            // var to = this.moneyDetails.currencyTo.name;
            // this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[from][to]).toFixed(2);
            this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.conversionRate).toFixed(2);
            this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountFrom).toFixed(2);
        },

        setcurrencyOnFrom(){
            // var from = this.moneyDetails.currencyFrom.name;
            // var to = this.moneyDetails.currencyTo.name;
            // this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[to][from]).toFixed(2);
            this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo / this.conversionRate).toFixed(2);
            this.timelineLists.totalpay = this.moneyDetails.currencyFrom.name +' '+parseFloat(this.moneyDetails.amountTo / this.conversionRate).toFixed(2);
        },

        swipeTextbox(){
            var currencyFrom = this.moneyDetails.currencyFrom
            var currencyTo = this.moneyDetails.currencyTo
            this.moneyDetails.currencyFrom = currencyTo
            this.moneyDetails.currencyTo = currencyFrom

            var amountFrom = this.moneyDetails.amountFrom
            var amountTo = this.moneyDetails.amountTo
            if(amountFrom){
                this.moneyDetails.amountFrom = parseFloat(amountTo).toFixed(2);
            }
            if(amountTo){
                this.moneyDetails.amountTo = parseFloat(amountTo * this.currencyRate[currencyTo.name][currencyFrom.name]).toFixed(2);
            }
        },
        sendMoneySubmit() {
            // console.log("send money successfully!!");
            if(this.moneyDetails.currencyFrom.walletid && this.moneyDetails.currencyTo.walletid && this.moneyDetails.currencyFrom.name && this.moneyDetails.amountFrom){
                this.$refs.loader.show();
                // console.log(this.swapMoneyData);
                let transferdata = {
                    fromWalletId : this.moneyDetails.currencyFrom.walletid,
                    toWalletId : this.moneyDetails.currencyTo.walletid,
                    amount : parseInt(parseFloat(this.moneyDetails.amountFrom).toFixed(2)),
                    currency : this.moneyDetails.currencyFrom.name
                }
                // console.log(transferdata);
                this.$http.post(this.$baseurl+'wallet/wallets/transfer', transferdata,{
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
                    },
                }).then((res) => {
                    console.log(res);
                    // this.$bvModal.show('swap_completed');
                    $toast.success('Transfer successful',  {
                            position:'top-right'
                        });
                    this.$router.push({path:'/admin/send-money/dashboard'});
                    this.$refs.loader.hide();
                }).catch((error) => {
                    // error.response.status Check status code
                    // console.log(error.response);
                    this.$refs.loader.hide();
                    if(error.response){
                        var message = '';
                        if(error.response.data.detail){
                            message = error.response.data.detail;
                        } else {
                            message = error.response.data.message;
                        }
                        $toast.error(message,  {
                            position:'top-right'
                        });
                    }
                });
            } else {
                $toast.error('Please fillup all field',  {
                            position:'top-right'
                        });
            }
        },

        async getBusinessWiseWallet(){
            // console.log('aaa',this.countrys_options);
            this.$refs.loader.show();
            const businessId = sessionStorage.getItem("businessId");
            if(businessId){
                try {
                    const response = await this.$http.get(this.$baseurl+"wallet/business/"+businessId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    });
                    // JSON responses are automatically parsed.
                    // console.log(response);
                    this.currencyOptions = [];
                    this.destcurrency = [];
                    if(response.data.code == '0100-000'){
                        this.totalRecord = response.data.details.length;
                        response.data.details.map((item) => {
                            let accountData = {
                                name: item.currencycode, 
                                flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-md fi-round",
                                walletid : item.walletid
                            }
                            this.currencyOptions.push(accountData);
                            
                            const fromcurency = this.walletDetails ? this.walletDetails.walletCurrency : item.currencycode;
                            const fromisocode = this.walletDetails ? this.walletDetails.isocode : item.isocode;
                            const fromwallet = this.walletDetails ? this.walletDetails.walletID : item.walletid;
                            // console.log('this.currencyOptions', fromcurency, fromisocode, fromwallet);
                            this.moneyDetails.currencyFrom = {
                                name: fromcurency, 
                                flagClass: "flag-icon flag-icon-"+fromisocode.toLowerCase()+" fi-md fi-round",
                                walletid : fromwallet
                            };
                            // console.log('this.moneyDetails.currencyFrom', this.moneyDetails.currencyFrom);

                            if(this.countrys_options.includes(item.currencycode)){
                                let accData = {
                                    name: item.currencycode, 
                                    flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-md fi-round",
                                    walletid : item.walletid
                                }
                                this.destcurrency.push(accData);
                                
                                if(this.destcurrency && this.destcurrency[0]){
                                    this.moneyDetails.currencyTo = {
                                        name: this.destcurrency[0].name, 
                                        flagClass: this.destcurrency[0].flagClass,
                                        walletid : this.destcurrency[0].walletid
                                    };
                                }
                            }
                            // console.log('sdsd',this.destcurrency);
                        })
                        this.getConversionrate();
                        this.$refs.loader.hide();
                    }
                } catch (error) {
                    console.log(error);
                    this.$refs.loader.hide();
                }
            } else {
                return [];
            }
        },

        async getTransferReason(){
            try {
                const response = await this.$http.get(this.$apiV2+"terrapay/remittance-purpose-list/",{
                    headers:{
                        Authorization : 'Basic ' + btoa(this.$payoutuser + ':' + this.$payoutpwd)//'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY='
                    }
                });
                this.reasonforTransferOption = [];
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                    let Data = {
                        id: item.id,
                        name: item.reason_name,
                    }
                    this.reasonforTransferOption.push(Data);
                })
            } catch (error) {
                console.log(error);
            }
        },

        async destinationcountry() {
            try {
                const paramdata = {
                    size : 150
                }
                const response = await this.$http.get(this.$baseurl+"countries/destination", { params: paramdata});
                this.countrys_options = [];
                // JSON responses are automatically parsed.
                response.data.details.map((item) => {
                    this.countrys_options.push(item.currencycode);
                })
                this.getBusinessWiseWallet();
            } catch (error) {
                console.log(error);
            }
        }, 

        async getConversionrate(){
            if(this.moneyDetails.currencyFrom.walletid && this.moneyDetails.currencyTo.walletid){
                try {
                    const paramdata = {
                        fromWalletId : this.moneyDetails.currencyFrom.walletid,
                        toWalletId : this.moneyDetails.currencyTo.walletid
                    }
                    const response = await this.$http.get(this.$baseurl+"wallet/conversion-rate",{
                        params: paramdata,
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    });
                    // JSON responses are automatically parsed.
                    // console.log(response);
                    if(response.data.rate){
                        this.conversionRate = response.data.rate;
                        this.timelineLists.exchangerate = '1 '+this.moneyDetails.currencyFrom.name+' = '+parseFloat(response.data.rate).toFixed(2);
                    }else{
                        $toast.error(response.data.message,  {
                            position:'top-right'
                        });
                        this.timelineLists.exchangerate = '0.00'
                    }
                } catch (error) {
                    // console.log(error);
                    $toast.error(error.response.data.message,  {
                        position:'top-right'
                    });
                    this.timelineLists.exchangerate = '0.00'
                }
            }
        }
    },
    mounted() {
        this.receiveMoneyByOptions.map((item) => {
            if (item.value == this.$route.query.type) {
                this.moneyDetails.receiveMoneyBy = item;
            }
        });
        this.getTransferReason();
        this.destinationcountry();
        // this.getBusinessWiseWallet();
       

        this.walletDetails = EventBus.getWalletDetails();
        // console.log('Wallet Details:', this.walletDetails);
    },
}

</script>
