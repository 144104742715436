<template>
    <ul class="list-unstyled search-list no-search-list send-list">
        <li>
            <a href="#" class="btn btn-add-new-recipients d-flex align-items-center justify-content-start mt-0">
                <i class="fa fa-bars"></i> Wallet List
            </a>
        </li>
        <li style="display:none">
            <a href="#" v-b-modal.addWallet class="btn btn-add-new-recipients d-flex align-items-center justify-content-start mt-0">
                <i class="fa fa-plus"></i> Add a Wallet Account
            </a>
            <b-modal id="addWallet" title="Add Wallet Details" hide-footer>
                <div class="modal-area"> 
                    <Form class="form-auth-small" @submit="addWalletAccount" :validation-schema="schema" v-slot="{ errors }" id="addWallet" ref="addWallet">
                        <div class="row">
                            <!-- Country -->
                            <div class="col-12">
                                <Field name="currency" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.currency,'success':!errors && !errors.currency}">
                                            <span class="float-label">
                                            <label for="Country">Select Currency*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addWallet.currency"
                                                    :show-labels="false" 
                                                    :options="currency_options"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <!-- Business Name -->
                            <div class="col-12">
                                <Field rules="required" name="account_name" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && errors.account_name,'success':!errors && !errors.account_name}">
                                        <span class="float-label">
                                            <input type="text" id="business_name" v-model="addWallet.account_name" @update:model-value="handleChange" class="form-control" placeholder="Business Name*">
                                            <label for="business_name" class="control-label">Account Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.account_name"></i>
                                            <i class="fa fa-check" v-if="addWallet.account_name && !errors.account_name"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique Account Name</span>
                                    </div>
                                </Field>
                            </div>
                            <!-- Country Code and Mobile Number -->
                            <div class="col-12">
                                <Field rules="required" name="mobile_number">
                                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':addWallet.mobile_number && mobileInputError}">
                                        <span class="float-label">
                                            <vue-tel-input
                                                v-model="addWallet.mobile_number"
                                                v-bind:id="primaryMobileNumber"
                                                @validate ="mobileInputValidate"
                                                ref="telInput">
                                                <template v-slot:arrow-icon>
                                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                </template>
                                            </vue-tel-input>
                                            <label for="primary_number" class="control-label">Contact Mobile Number*</label>
                                            <div class="line"></div>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique business name</span>
                                    </div>
                                </Field>
                            </div>
                            <!-- Email -->
                            <div class="col-12">
                                <Field name="email" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && errors.email,'success':!errors && !errors.email}">
                                        <span class="float-label">
                                            <input type="email" v-model="addWallet.email" @update:model-value="handleChange" class="form-control" name="email1" id="contact_email" placeholder="Email">
                                            <label for="email" class="control-label">Email</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.email"></i>
                                                    <i class="fa fa-check" v-if="!errors && !errors.email"></i>
                                        </span>
                                    </div>
                                </Field>
                            </div>
                        </div>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg">Add Account</b-button>
                            <b-button type="submit" block class="btn btn-lg btn-outline-secondary" size="lg" @click="$bvModal.hide('addWallet')">Cancel</b-button>
                        </div>
                    </Form>
                </div>
            </b-modal>
        </li>
        <li v-for="(wallet,index) in WalletAccount" :key="index">
            <span class="send-list-card" @click="setWallet(wallet)" style="cursor:pointer">
                <!-- <router-link :to="{ path: '/admin/send-money/receive-money-by',query:{ type: $route.query.type }}" class="send-list-card"> -->
                <div class="d-flex align-items-center">
                    <div class="mobile-img">
                        <img :src="wallet.image" alt="">
                    </div>
                    <div class="name ml-3">
                        <h6 class="mb-1">{{ wallet.name }}</h6>
                        <p class="mb-0 d-flex align-items-center">
                            <span :class="wallet.flagIcon"></span>
                            <strong class="ml-2">{{ wallet.number }}</strong>
                        </p>
                    </div>
                </div>
                <div class="d-flex align-items-center icon-right">
                    <span class="text-muted font-18">{{ wallet.balance }}</span>
                    <i class="arrow-right" v-html="wallet.arrowImage"></i>
                </div>
            </span>
        </li>
    </ul>
    <main-loader ref="loader"></main-loader>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
const getSymbolFromCurrency = require('currency-symbol-map')
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default;
import {EventBus} from '@/eventBus';
// import walletService from '@/services/walletService';
export default {
    name:'WalletAccount',
    components: {
        Form,
        Field,
        'Multiselect': VueMultiselect,
        "main-loader": LoaderComponent,
    },
    data(){
        return{
            mobileInputError:'',
            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            },
            addWallet: {
                currency:{ name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-md fi-round"},
                account_name: null,
                country_code: null,
                mobile_number: null,
                email: null
            },
            currency_options:[
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-md fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-md fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-md fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],
            WalletAccount:[]
        }
    },
    methods: {
        addWalletAccount(){
            console.log('Add wallet method called');
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        formateNumbers(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        async getBusinessWiseWallet(){
            this.$refs.loader.show();
            const businessId = sessionStorage.getItem("businessId");
            if(businessId){
                try {
                    const response = await this.$http.get(this.$baseurl+"wallet/business/"+businessId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    });
                    // JSON responses are automatically parsed.
                    // console.log(response);
                    this.WalletAccount = [];
                    if(response.data.code == '0100-000'){
                        // this.totalRecord = response.data.details.length;
                        response.data.details.map((item) => {
                            let listviewData = {
                                walletid : item.walletid,
                                name:item.name,
                                number: item.currencycode,//'Ghanaian Cedi',
                                isocode: item.isocode,
                                flagIcon: 'flag-icon flag-icon-'+item.isocode.toLowerCase()+' fi-md fi-round',
                                arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                                </svg>`,
                                balance: getSymbolFromCurrency(item.currencycode) + this.formateNumbers(parseFloat(item.availablebalance).toFixed(2))
                            }
                            this.WalletAccount.push(listviewData);
                        })
                        this.$refs.loader.hide();
                    }
                } catch (error) {
                    console.log(error);
                    this.$refs.loader.hide();
                }
            } else {
                return [];
            }
        },
        setWallet(walletDetail) {
            const walletDetails = {
                walletID: walletDetail.walletid,
                walletCurrency: walletDetail.number,
                isocode: walletDetail.isocode
            };
            EventBus.setWalletDetails(walletDetails);
            this.$router.push({
                path: '/admin/send-money/receive-money-by',
                query: { type: this.$route.query.type }
            });
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            if(sessionStorage.getItem("businessId")){
                this.getBusinessWiseWallet()
            }
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/authentication/page-login'});
        }
    }
}
</script>