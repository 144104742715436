import { reactive } from 'vue'

export const EventBus = reactive({
    walletDetails: null,
    setWalletDetails(details) {
        this.walletDetails = details
    },
    getWalletDetails() {
        return this.walletDetails
    }
})