<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card card-top-line no-shadow send-money-area">
                    <div class="header">
                        <h2>How do you want to receive the money?</h2>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-lg-4">
                                <money_exchange_options></money_exchange_options>
                            </div>
                            <!-- <div class="col-lg-4">
                                <div class="card border rounded no-shadow">
                                    <money_exchange_timeline></money_exchange_timeline>
                                </div>
                            </div> -->
                            <div class="col-lg-8">
                                <money_exchange_by></money_exchange_by>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import money_exchange_options from '@/components/admin/send_money/send_myself/MoneyExchangeOptions.vue';
// import money_exchange_timeline from '@/components/admin/send_money/send_myself/MoneyExchangeTimeline.vue';
import money_exchange_by from '@/components/admin/send_money/send_myself/MoneyExchangeBy.vue';
export default {
    name:'SendMoneyBy',
    components: {
        BreadCrumb,
        money_exchange_options,
        // money_exchange_timeline,
        money_exchange_by,
    }
}

</script>
